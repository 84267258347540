/*** common ************************/

body#home, body#main-contents{
	background: url(../images/back.jpg) repeat-x;
}

body#other-contents{
	background: url(../images/back_other.jpg) repeat-x;
}

html{
	font-size: 62.5%;
}

a{
	color: #004782;
}

a:visited{
	color: #8c006d;
}

.floatl{
	float:left;
}
.floatr{
	float: right;
}

.block{
	overflow: hidden;
}
.clear{
	clear:both;
}

/* Gnavi */

#gnavi{
	clear: both;
	width: 960px;
	height: 46px;
	margin: 0 auto;
	ul{
		list-style: none;
		li{
			float: left;
			a{
				text-decoration: none;
				color:#fff;
				font-size: 16px;
				line-height: 46px;
				display: block;
				padding: 0 20px;
				&:hover{
					background: url(../images/gn-back.png) repeat-x;
				}
			}
		}
	}
}

/* main-img */

#main-img{
	width: 960px;
	margin: 0 auto;
	display: block;
	height: 442px;
	position: relative;
	img.main-text01{
		position: absolute;
		top:10px;
		left:5px;
	}
	img.main-text02{
		position: absolute;
		left:17px;
		top:135px;
	}

	img.main-img02{
		position: absolute;
		bottom:0;
		right:0;
	}
	#main-img-point{
		width: 540px;
		height: 260px;
		background: url(../images/main-img-point-back.png) no-repeat;
		position: absolute;
		bottom:0;
		ul.point{
			position: relative;
			list-style: none;
			margin: 0px 0 0 39px;
			li{
				float: left;
				padding: 0 10px 6px 0;
			}
		}
		span{
			clear: both;
			display: block;
			padding:4px 15px 0;
			text-align: right;
		}
		.point-imgA{
			img{
				width: 100%;
			}
			.point-listA{
				list-style: none;
				margin: 0 auto;
				padding: 0;
			}
		}
	}
}

.wrapper{
	width: 960px;
	margin: 30px auto;
	font-size: 14px;
	.content-wrapper{
		width: 720px;
		float: left;
		margin: 0;
	}
}



#home .content-pickup{
	margin:-15px auto 36px auto;
	h2{
		padding: 2px 0 0 0;
		border-top:1px #0054a9 solid;
	}
	.pickup-wrapper{
		background: url(../images/check-gray-02.png);
		overflow: hidden;
		border: 1px #999 solid;
		margin: 7px auto;
		padding: 10px;
		ul.list01{
			& > li{
				width: 210px;
				background: #fff;
				float: left;
				margin: 10px;

				.h3title{
					border:1px #fff solid;
					background: #ebe6c4;
					background: -webkit-gradient(linear, left top, left bottom, color-stop(1.00, #e7e3be), color-stop(0.00, #faf7e0));
					background: -webkit-linear-gradient(#faf7e0, #e7e3be);
					background: -moz-linear-gradient(#faf7e0, #e7e3be);
					background: -o-linear-gradient(#faf7e0, #e7e3be);
					background: -ms-linear-gradient(#faf7e0, #e7e3be);
					background: linear-gradient(#faf7e0, #e7e3be);
					h3{
						border-left:7px #d00 solid;
						margin: 7px 10px;
						line-height: 1.2;
						padding: 0 0 0 10px;
					}
				}
				h3{
					font-size: 18px;
					font-size: 1.8rem;
					font-weight: bold;
					color: #444;
				}
			}
		}
		.list02{
			clear: both;
			& > li{
				background: #cbd8e6;
				width: 320px;
				float: left;
				border:1px #fff solid;
				margin: 10px 12px;
				h3{
					line-height: 2;
					float: left;
					font-weight: bold;
					font-size: 18px;
					font-size: 1.8rem;
					color:#444;
					width:28%;
					margin:7px 5px;
					padding-left: 13px;
					border-left:12px #d00 solid;
				}
			}
		}
		ul {
			list-style: none;
			li{
						box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
		-webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
			}
			p{
			font-size: 12px;
			font-size: 1.2rem;
			line-height: 1.3;
			padding:10px;
		}

		}

	}
	li a{
		text-decoration: none;
		&:hover{
			text-decoration: underline;
		}
	}
			p.lead{
			font-size: 20px;
			font-size: 2.0rem;
			line-height: 1.3;
			margin:1rem 0 0;
			padding: 1rem 1.5rem;
			border:2px #d00 solid;
		}
}

#home .content-negotiate{
	margin: 50px auto 30px;
	p.midashi{
		border-left:#666 10px solid;
		margin: 15px 0 0px 10px;
		line-height: 1.4;
		font-size: 24px;
		font-size: 2.4rem;
		padding: 5px 0 5px 20px;
		font-weight: bold;
	}
	.negotiate-img{
		margin: 15px 0 15px 25px;
		border:1px #999 solid;
		background: -webkit-gradient(linear, left top, left bottom, color-stop(1.00, #f3f3f3), color-stop(0.00, #fff));
background: -webkit-linear-gradient(#fff, #f3f3f3);
background: -moz-linear-gradient(#fff, #f3f3f3);
background: -o-linear-gradient(#fff, #f3f3f3);
background: -ms-linear-gradient(#fff, #f3f3f3);
background: linear-gradient(#fff, #f3f3f3);

		ul{
			list-style: none;
			// padding: 12px 0 12px 30px;
			padding: 12px 0 3px 30px;
			overflow: hidden;
			li{
				float: left;
			}
		}
		.negotiate-img-note{
			padding: 0 0 12px 0;
			margin-left: 30px;
			font-size: 1.2rem;
		}
	}
	img.negotiate-img04{
			float: left;
			padding: 0 25px;
		}
		p{
			line-height: 1.4;
			padding: 12px;
		}
		.negotiate-box-instance{
			clear: both;
			overflow: hidden;
			margin: 30px 0 15px 10px;
		h3.blue-line01{
			background: url(../images/blue-line01.png) no-repeat left bottom ;
			font-size: 18px;
			font-size: 1.8rem;
			line-height: 2.5;
			padding: 5px 0 5px 20px;
			font-weight: bold;
		}
		ul{
			margin:15px 25px;
			list-style: none;
			display: block;
		display:-webkit-box;
		display:-moz-box;
		display:-webkit-flexbox;
		display:-moz-flexbox;
		display:-ms-flexbox;
		display:-webkit-flex;
		display:-moz-flex;
		display:flex;
		-webkit-flex-pack:distribute;
		-moz-flex-pack:distribute;
		-ms-flex-pack:distribute;
		-webkit-justify-content:space-around;
		-moz-justify-content:space-around;
		justify-content:space-around;
		-webkit-box-lines:multiple;
		-moz-box-lines:multiple;
		-webkit-flex-wrap:wrap;
		-moz-flex-wrap:wrap;
		-ms-flex-wrap:wrap;
		flex-wrap:wrap;
			li{
				width: 48%;
				background: url(../images/back-gray.png) repeat-x;
				width: 310px;
				border:1px #999 solid;
				//-float: left;
				margin: 5px 0px 12px 18px;
				.instance-header{
					padding: 10px;
					line-height: 1.3;
				img.icon-user{
					float: left;
					padding: 0 10px 0 0;
				}
				}
				table{
					width: auto;
					margin:5px 10px 10px;
					padding:0;
					border-collapse: collapse;
					border: 1px #999 solid;
						font-size: 12px;
						font-size: 1.2rem;
					th{
						width: 50%;
						border-collapse: collapse;
					border: 1px #999 solid;
					vertical-align: middle;
					font-weight: normal;
					padding-left: 5px;
						&.fth{
							background: #ccc;
						}
						&.ath{
								background: #c1d3e3;
							}
						&.totalth{
							font-size: 14px;
							font-size: 1.4rem;
							color:#d00;
						}
						&.result{
							font-size: 16px;
							font-size: 1.6rem;
							font-weight: bold;
							background: #0089c9;
							color:#fff;
							border-right:1px #eee solid;
						}
					}
					td{
						border-collapse: collapse;
					border-bottom: 1px #999 solid;
						padding: 5px;
						white-space: nowrap;
						text-align:right;
						&.totalth{
							font-size: 14px;
							font-size: 1.4rem;
							color:#d00;
						}
						&.result{
							font-size: 16px;
							font-size: 1.6rem;
							font-weight: bold;
							background: #0089c9;
							color:#fff;
						}
					}
				}
			}
		}
	}
}

.tokuyaku-banner{
	height: 310px;
	background: url(../images/tokuyaku-home-img.png);
	position: relative;
	overflow: hidden;
	margin-top:-20px;

	img.text01{
		position: absolute;
		top:85px;
		right:12px;
	}
	& > .link{
		position: absolute;
		right:15px;
		bottom:10px;
	}
}

.info-contact-box-01{
	overflow: hidden;
	padding: 10px;
	border:4px #01508f solid;
	& > p{
		font-weight: bold;
		line-height: 1.5;
		font-size: 16px;
		font-size: 1.6rem;
		span{
			font-size: 1.1rem;
		}
	}
	.mail{
		float: left;
	}
	.tel{
		float: left;
	}
}

.note01{
	display: block;
	text-align: right;
	line-height: 1.4;
	font-size: 1.2rem;
	padding: 0.5rem 1rem;
}

#other-contents .negotiate-box-instance{
	margin: 20px auto;
	font-size: 16px;
	font-size: 1.6rem;
	ul{
		list-style: none;
		li{
			margin: 5px 0 40px;

				.instance-wrapper{
			background: url("../images/back-gray.png") repeat-x scroll 0 0 rgba(0, 0, 0, 0);
		border: 1px solid #999;
		margin: 10px 0 15px 40px;
		.instance-header{
			margin: 10px 15px;
			border-bottom:1px #999 dotted;
			img{
				vertical-align: middle;
				padding: 0 15px 5px 0;
			}
		}
		table{
			margin: 15px 60px;
			border:1px #999 solid;
			border-collapse: collapse;
			width: 80%;
			font-size: 1.4rem;
			th {
				padding: 10px;
				border-collapse: collapse;
				border:1px #999 solid;
										&.fth{
							background: #ccc;
						}
						&.ath{
								background: #c1d3e3;
							}
						&.totalth{
							color:#d00;
						}
						&.result{
							font-weight: bold;
							background: #0089c9;
							color:#fff;
							// border-right:1px #eee solid;
						}
			}
			td{
				padding: 10px;
				border-collapse: collapse;
				border:1px #999 solid;
										white-space: nowrap;
						text-align:right;
						&.totalth{
							color:#d00;
							font-size: 1.6rem;
							font-weight: bold;
						}
						&.result{
							font-size: 20px;
							font-size: 2.0rem;
							font-weight: bold;
							background: #0089c9;
							color:#fff;
						}
			}

		}
		p{
			line-height: 1.3;
			margin: 15px 70px;
			font-size: 14px;
			font-size: 1.4rem;
		}
		}
		ul.note-ul{
			margin: 5px 15px 5px 10%;
			padding: 0;
			li{
				margin: 5px 15px;
				padding: 5px;
				list-style-type: square;
			}
		}
		.commentBox{
			margin: 15px 60px;
			border: double 3px #01508f;
			width: 80%;
			p{
				margin: 10px auto;
				padding:0 10px;
				line-height: 1.5;
				span{
					background-color: #01508f;
					color:#fff;
					border-radius: 4px;
					padding: 2px 5px;

				}
			}
		}
		}

	}
}

#home .content-merit{
	.reason-note{
		margin: 15px 35px;
		line-height: 1.4;
		p{
			padding: 5px;
			span{
				font-size: 16px;
				font-size: 1.6rem;
				font-weight: bold;
			}
		}
	}
	h3{
		font-weight: bold;
		margin: 10px 0 10px 10px;
		line-height: 48px;
		font-size: 18px;
		font-size: 1.8rem;
		border: 1px #3c95d6 solid;
background: -webkit-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
background: -moz-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
background: -o-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
background: -ms-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
background: linear-gradient(to bottom, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
		img{
			vertical-align: middle;
			padding-right:10px;
		}
	}
	ol{
		list-style: none;
		li{
			margin: 0 0 0 25px;
			h4{
				background: url(../images/merit-blueline.png) no-repeat;
				line-height: 50px - 5;
				margin: 5px 0 ;
				font-size: 18px;
				font-size: 1.8rem;
				padding: 7px 0 0 0;
				span{
		display: block;
		float: left;
		text-align: center;
		width:40px;
		color:#fff;
				}
			}
			.note-block{
				background: url(../images/merit-shade.png) no-repeat right bottom;
				min-height: 120px;
				overflow: hidden;
				.note{

					min-height: 108px;
					margin: 0px 10px 15px 0;
					background: url(../images/check-gray.png) right bottom;
					border:1px #cecece solid;
					overflow: hidden;
					p{
						float: left;
						margin: 20px 0 20px 30px;
						width: 560px;
						line-height: 1.4;
						span{
							color:#d00;
						}
					}
					.arrow{
						margin: 20px 15px 0 0px;
						width: 60px;
						float: right;
						font-size: 12px;
						font-size: 1.2rem;
					}
				}
			}
		}
	}
}

#home .content-pr{
	margin: 36px auto 10px;
.pr-note{
	padding: 15px 40px;
	line-height: 1.4;
		& > p{
			font-size: 18px;
			font-size: 1.8rem;
		}
		p.txt-s{
				font-size: 12px;
	font-size: 1.2rem;
	padding: 0.5rem 1.5rem 1.5rem;
	float:left;
		}
		.pr-note-blue-ul{
			overflow: hidden;
	margin: 0px 0px;
	background: url(../images/paper-blue.png);
		box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
		-webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
	ul{
		font-size: 24px;
		font-size: 2.4rem;
			list-style: none;
			width:440px;
			margin:0 15px 0 170px;
			padding: 10px 0;
			line-height: 1.4;
			li{
				padding: 10px 0;
				font-weight: bold;
				& > span{
					color:#0054a9;
				}
			}
	}
}
.pr-img01{
	margin-top:-200px;
	float: left;
}
}
}



#home .content-info, #main-contents .content-info{
	margin: 30px auto 0;
	.info-note{
		padding: 15px 40px;
		line-height: 1.4;
		& > p{
			font-size: 18px;
			font-size: 1.8rem;
		}
	}
	.info-note-blue{
		overflow: hidden;
		padding: 15px 0 0;
		background: #e2f5ff;
		background: -webkit-gradient(linear, left top, left bottom, color-stop(0.88, #def3ff), color-stop(0.00, #f3fbff));
background: -webkit-linear-gradient(top, #f3fbff 0%, #def3ff 88%);
background: -moz-linear-gradient(top, #f3fbff 0%, #def3ff 88%);
background: -o-linear-gradient(top, #f3fbff 0%, #def3ff 88%);
background: -ms-linear-gradient(top, #f3fbff 0%, #def3ff 88%);
background: linear-gradient(to bottom, #f3fbff 0%, #def3ff 88%);
.info-note-blue-ul{

	margin: 0px 35px;
	background: url(../images/paper-blue.png);
		box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
		-webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
	ul{
		font-size: 18px;
		font-size: 1.8rem;
			list-style: none;
			width:420px;
			margin:0 25px;
			padding: 15px 0;
			line-height: 1.4;
			li{
				padding: 10px 0;
			}
	}
}
.info-img01{
	vertical-align: bottom;
}
.info-note2{
	margin: 25px 0 25px 35px;
	width:415px;
	line-height: 1.3;
	p{
		padding: 5px;
		font-size: 18px;
		font-size: 1.8rem;
	}
}
.info-contact-box{
	width: 688px;
	margin: 30px 0 10px 25px;
	& > .tel{
		font-size: 16px;
		font-size: 1.6rem;
		line-height: 1.3;
		width:401px;
		span{
			font-size: 1.2rem;
		}
		p{
			text-align: right;
			padding-right: 20px;
		}
	}
	& > .mail{
		float: right;
		text-align: center;
		margin:-435px 0 20px 0;
	}
}
	}
}

.return-wrapper{
	display: block;
	clear: both;

	a{
		margin: 5px 0;
		float: right;
		border:1px #005497 solid;
		line-height: 2.5;
		text-decoration: none;
		padding: 3px 10px 3px 35px;
		color:#005497;
		background: url(../images/arrow-up_off.png) no-repeat 10px center;
		&:hover{
			background: url(../images/arrow-up_on.png) no-repeat 10px center,#005497;
			color:#fff;
		}
	}

	img{
		vertical-align: middle;
		padding: 0 5px;
	}
}


/* other page */

.breadcrumbs{
	font-size: 12px;
	font-size: 1.2rem;
	line-height: 2;
	overflow: hidden;
	ul,ol{
		list-style: none;
		li{
			display: inline;

			a {
			  padding: 0.3rem 0.5rem;
			}
			a:hover{
				color:#f00;
			}
			&::before {
			  content: " > ";
			}
			&:first-child::before {
			  content: "";
			}
		}
	}
}

body#other-contents{
	.content{
		margin: 7px auto 30px;
		.h2title{
			background: url(../images/h2title_back.png) no-repeat;
			line-height: 70px;
			color:#fff;
			font-weight: bold;
			font-size: 22px;
			font-size: 2.2rem;
			padding: 0 0 0 15px;
		}
			h3.h3title{
				clear: both;
		font-weight: bold;
		margin: 30px 0 10px 10px;
		line-height: 48px;
		font-size: 18px;
		font-size: 1.8rem;
		border: 1px #3c95d6 solid;
		padding: 0 0 0 30px;
background:url(../images/h3-blue-img.png) no-repeat left center, -webkit-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
background:url(../images/h3-blue-img.png) no-repeat left center, -moz-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
background:url(../images/h3-blue-img.png) no-repeat left center, -o-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
background:url(../images/h3-blue-img.png) no-repeat left center, -ms-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
background:url(../images/h3-blue-img.png) no-repeat left center, linear-gradient(to bottom, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
		}
	}
}

.content-single{
	.note{
		margin: 15px 25px;
		line-height: 24px;
		line-height: 2.4rem;
		background: url(../images/border-dot.png) left bottom;
		p{
			margin: 0 0 24px 0;
		}
		ul,ol{
			margin:0 15px 24px;
		}
		table{
			margin:0 15px 24px;
			td{
				padding: 0 10px 0 0;
			}
		}
	}

.note-kouishou{
	margin: 15px 0px 15px 25px;
	h4{
		line-height: 36px;
		font-weight: bold;
		margin: 24px 0 12px 120px;
		background:#3c95d6;
		color:#fff;
		padding: 0 0 0px 15px;
		border: 1px #1b75b7 solid;
	}
	img{
		float: left;
				box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
		-webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
		margin: 0px 15px 24px 0;
	}
		p{
			margin: 0 0 24px 130px;
		}
}
}

.kouishou-link{
	margin: 30px 0 10px 10px;
	border: 3px #d8cbba solid;
	background: #fbfaed;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(1.00, #f8f6e4), color-stop(0.00, #fbfaed));
background: -webkit-linear-gradient(#fbfaed, #f8f6e4);
background: -moz-linear-gradient(#fbfaed, #f8f6e4);
background: -o-linear-gradient(#fbfaed, #f8f6e4);
background: -ms-linear-gradient(#fbfaed, #f8f6e4);
background: linear-gradient(#fbfaed, #f8f6e4);
ul{
	list-style: none;
	padding: 20px 10px;
	overflow: hidden;
	li{
		padding: 5px 15px;
		float: left;
		border-right:1px #999 solid;
		a{
			padding: 3px;

			&:hover{
				border-radius: 4px;
				background: #d8cbba;
			}
		}
	}
}
}

.kouishou-list ul{
	margin:30px 50px;
	width: 70%;
	li{
		padding: 10px 0;
		border-bottom: #999 1px dotted;
		line-height: 1.5;
	}
}

.return-kouishou-nav{
	margin: 15px 0 30px 15px;
	overflow: hidden;
	a{
		border:1px #005497 solid;
		text-decoration: none;
		color:#005497;

		float: right;
		border: 1px #999 solid;
		padding: 10px 15px;
		font-size: 12px;
		font-size: 1.2rem;
		&:hover{
			background: #005497;
			color:#fff;
		}
	}
}


.attention{
	border:3px #d00 solid;
	margin: 30px 0 10px 10px;
	padding: 15px 25px;
	line-height: 1.5;
	font-size: 16px;
	font-size: 1.6rem;
	color:#fff;
	background: #ff0001;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0.00, #ff0001), color-stop(1.00, #8f0202));
background: -webkit-linear-gradient(#ff0001, #8f0202);
background: -moz-linear-gradient(#ff0001, #8f0202);
background: -o-linear-gradient(#ff0001, #8f0202);
background: -ms-linear-gradient(#ff0001, #8f0202);
background: linear-gradient(#ff0001, #8f0202);

}

#dl-profile{
	margin:30px 0 20px 20px;
	dl{
		line-height: 1.5;
		dt{
			color: #006699;
			padding: 10px 0 0 0.5rem;
			width: 13rem;
		}
		dd{
			border-bottom: 1px dotted #999;
			margin-top: -1.5em;
			padding: 0 0 10px 14rem;
			&.author_photo{
				overflow: hidden;
				span{
					float: left;
				}
				img{
					width:120px;
					padding:0 0 0 7rem;
				}
			}
		}
	}
}
#contents-access{
	overflow: hidden;
	#mapdl{
		float: left;
		padding: 5px 35px 5px 15px;
		dl{
			line-height: 1.5;
			width: 300px;
			dt{
				background: 0 0 #efefef;
			}
			dd{
				padding: 5px 0 15px 10px;
			}
		}
	}
}

#contents-privacy{
	font-size: 14px;
	line-height: 1.3;
	p{
		padding: 8px 15px;
	}
	#privacy-dl{
		margin: 15px;
		dt{
			border-bottom: 1px solid #ccc;
		}
		dd{
			padding: 8px 15px 20px;
		}
	}
}

.content-qa{
	dl{
		margin: 20px 0 20px 15px;

		dt{
			padding: 15px 0 0 0;

			overflow: hidden;
			h3{
				font-size: 16px;
			font-size: 1.6rem;
			color:#0067b3;
			padding: 5px 0 0 0;
			line-height: 1.3;
			}
			img{
				float: left;
				vertical-align: middle;
				padding: 0 15px 0 0 ;
			}
		}
		dd{
			padding:5px 0 15px;
			border-bottom:1px #ccc solid;
			.answer{
				padding: 15px 0 15px 15px;
				img{
					float: left;
					padding: 0 15px 0 0 ;
				}
				p{
					font-size: 14px;
					font-size: 1.4rem;
					line-height: 1.4;
					color: #555;
					padding-left: 60px;
				}
				table{
					color:#555;
					margin: 15px 15px 15px 70px;
					border: 1px #999 solid;
					border-collapse:collapse;
					th,td{
						padding: 5px 10px;
						border: 1px #999 solid;
					border-collapse:collapse;
					}
				}
			}
		}
	}

}


.trouble-list-wrapper{
	background: url(../images/fukidashi.png) no-repeat left top;
	position: relative;
	min-height: 357px;
	img.merit-img01{
		float: right;
		vertical-align: bottom;
	}
	ul.trouble-list{
		list-style: none;
		li{
			line-height: 1.2;
			font-size: 16px;
			font-size: 1.6rem;
			span{
				font-weight: bold;
			}
		}
		li.trouble01{
			position: absolute;
			top:25px;
			left:67px;
			font-size: 18px;
			font-size: 1.8rem;
		}
		li.trouble02{
			position: absolute;
			top:76px;
			left:227px;
		}
		li.trouble03{
			position: absolute;
			left:53px;
			top:125px;
		}
		li.trouble04{
			position: absolute;
			top:145px;
			left:243px;
			font-size: 24px;
			font-size: 2.4rem;
		}
		li.trouble05{
			position: absolute;
			top:200px;
			left:63px;
			font-size: 18px;
			font-size: 1.8rem;
		}
		li.trouble06{
			position: absolute;
			top:237px;
			left:276px;
		}
		li.trouble07{
			position: absolute;
			top:290px;
			left:107px;
		}
	}
}
.trouble-list-footer, .tokuyaku-list-footer{
		background: #0054a9;
		color:#fff;
		font-size: 20px;
		font-size: 2.0rem;
		padding: 20px 25px;
		line-height: 1.4;
}

.tokuyaku-list-wrapper{
	background: url(../images/tokuyaku-box-back.jpg) no-repeat left top;
	min-height: 645px;
	position: relative;
	.tokuyaku-text01{
		position: absolute;
		top:45px;
		left:95px;
	}
	.tokuyaku-text02{
		position: absolute;
		top:195px;
		left:220px;
	}
	.tokuyaku-list{
		width: 360px;
		position: absolute;
		top:365px;
		left:300px;
		font-size: 18px;
		font-size: 1.8rem;
		line-height: 1.4;
		list-style: none;
		li{
			padding: 10px 0;
		}
	}
}

.content-tokuyaku{
				h3.h3title{
				clear: both;
		font-weight: bold;
		margin: 30px 0 10px 10px;
		line-height: 48px;
		font-size: 18px;
		font-size: 1.8rem;
		border: 1px #3c95d6 solid;
		padding: 0 0 0 30px;
background:url(../images/h3-blue-img.png) no-repeat left center, -webkit-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
background:url(../images/h3-blue-img.png) no-repeat left center, -moz-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
background:url(../images/h3-blue-img.png) no-repeat left center, -o-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
background:url(../images/h3-blue-img.png) no-repeat left center, -ms-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
background:url(../images/h3-blue-img.png) no-repeat left center, linear-gradient(to bottom, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
		}
		.tokuyaku-box{
			.note{
				margin: 20px 0 20px 40px;
				p{
					padding: 10px 5px;
					line-height: 1.4;
				}
			}
		}
}


#main-contents .merit-box{
	margin: 20px auto;
	h3.merit-title{
		border-left:#477eb6 solid 15px;
		color:#0054a9;
		font-size: 30px;
		font-size: 3.0rem;
		font-weight: bold;
		padding: 10px 15px;
		line-height: 1.5;
		margin: 10px 0 10px 10px;
	}
	.merit-box-note{
		margin-left:25px;
	.merit-voice{
		background: url(../images/check-gray.png);
		border:1px #ccc solid;
		margin: 20px 0 0 0;
		img.icon{
			float: left;
			padding: 10px;
		}
		p{
			line-height: 1.4;
			font-size: 18px;
			font-size: 1.8rem;
			margin: 15px 20px 15px 100px;
			span{
				font-size: 14px;
				font-size: 1.4rem;
			}
		}
	}
	.merit-h4-wrap{
		background: #01adc8;
		background: -webkit-gradient(linear, left top, left bottom, color-stop(1.00, #00c0df), color-stop(0.10, #02abc6));
background: -webkit-linear-gradient(top, #02abc6 10%, #00c0df 100%);
background: -moz-linear-gradient(top, #02abc6 10%, #00c0df 100%);
background: -o-linear-gradient(top, #02abc6 10%, #00c0df 100%);
background: -ms-linear-gradient(top, #02abc6 10%, #00c0df 100%);
background: linear-gradient(to bottom, #02abc6 10%, #00c0df 100%);
		color:#fff;
		padding: 13px;
		margin: 15px 0 25px;
		span{
			border-radius: 6px;
			border:1px #FFF solid;
			background: #006cad;
			color: #fff;
			font-size: 14px;
			font-size: 1.4rem;
			font-weight: bold;
			width:6rem;
			display: block;
			padding:5px 8px;
			line-height: 1.2;
			float: left;
			background: -webkit-gradient(linear, left top, left bottom, color-stop(0.88, #0067a5), color-stop(0.00, #0085d3));
background: -webkit-linear-gradient(top, #0085d3 0%, #0067a5 88%);
background: -moz-linear-gradient(top, #0085d3 0%, #0067a5 88%);
background: -o-linear-gradient(top, #0085d3 0%, #0067a5 88%);
background: -ms-linear-gradient(top, #0085d3 0%, #0067a5 88%);
background: linear-gradient(to bottom, #0085d3 0%, #0067a5 88%);

		}
		h4{
			line-height: 1.3;
			margin: 0 0 0 85px;
			font-size: 24px;
			font-size: 2.4rem;
			font-weight: bold;
		}
	}
	.merit-headline{
		font-size: 16px;
		font-size: 1.6rem;
		line-height: 1.3;
		margin: 15px 0 5px 15px;
		padding: 15px;
		background:#EFEFEF;
	}
	.note{
		img{
			float: right;
			padding: 15px 10px 5px 20px;
		}
		p{
			padding: 20px 25px;
			line-height: 1.5;
		}
		ul{
			padding: 10px 45px;
			line-height: 1.3;
			li{
				padding: 5px 0;
				span{
					font-weight: bold;
				}
			}
		}
	}
}
}

.content-single{
	.note-voice{
		margin: 20px 0 20px 25px;
		border: 1px #ccc solid;
		padding:24px 15px 0;
		box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
		-webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
	}
}

.attention-fee{
	margin: 20px 0;
	border:1px #d00 solid;
	padding: 15px;
	h3.attentiontitle{
		font-size: 16px;
		font-size: 1.6rem;
		margin: 5px;
		padding: 5px 10px;
		border-left:10px #d00 solid;
	}
	p{
		line-height: 1.3;
		padding: 10px 15px;
	}
}

.note-fee{
	p{
		padding: 10px 25px;
		line-height: 1.3;
		span{
			font-size: 24px;
			color:#d00;
		}
	}
	h4{
		background: none repeat scroll 0 0 #3c95d6;
		border: 1px solid #1b75b7;
		color: #fff;
		font-weight: bold;
		line-height: 36px;
		margin: 24px 0 12px 15px;
		padding: 0 0 0 15px;
	}
}

.note-fee{

	table{
		width: calc(100% - 40px);
		margin:35px 20px 5px;
		border-collapse: collapse;
		border:1px #666 solid;
		th,td{
			border-collapse: collapse;
			border:1px #666 solid;
			vertical-align: middle;
			line-height: 1.6;
			text-align: left;
			padding: 5px 15px;
			width: 50%;
			font-weight: 500;
		}
		tr:first-child{
			th{
				font-weight: bold;
			}
		}
	}
	p{
		line-height: 1.6;
	}
	p.spotlight{
		font-weight: bold;
	}
	ul{
		list-style: none;
		li{
			line-height: 1.6;
		}
	}
}


.word-link{
	margin: 30px 0 10px 10px;
	border: 3px #d8cbba solid;
	background: #fbfaed;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(1.00, #f8f6e4), color-stop(0.00, #fbfaed));
background: -webkit-linear-gradient(#fbfaed, #f8f6e4);
background: -moz-linear-gradient(#fbfaed, #f8f6e4);
background: -o-linear-gradient(#fbfaed, #f8f6e4);
background: -ms-linear-gradient(#fbfaed, #f8f6e4);
background: linear-gradient(#fbfaed, #f8f6e4);
table{
	margin: 15px;
	th{
		white-space: nowrap;
		font-weight: normal;
		padding: 10px;
	}
	td{
		border-bottom:1px #d8cbba solid;
		ul{
			list-style: none;
		li{
			float: left;
			padding: 0 10px;
			a:hover {
		background: none repeat scroll 0 0 #d8cbba;
		border-radius: 4px;
}
		}
				}
	}
}
}

.word-list ul{
	margin:30px 50px;
	width: 90%;
	list-style: none;
	li{
		padding: 10px 0;
		border-bottom: #999 1px dotted;
		line-height: 1.5;
		h4{
			background: #EFEFEF;
			font-weight: bold;
			padding: 5px 10px;
			margin: 5px 0 10px;
		}
		p{
			padding-left:15px;
		}
	}
}

.flow-box{
	margin: 20px 0 20px 20px;
	& > ol{
		list-style: none;
		li{
			overflow: hidden;
			p{
				margin: 10px 15px 10px 140px;
				font-size: 16px;
				line-height: 1.3;
				span{
					color:#d00;
				}
				span.tyui{
					font-size: 12px;
					font-size: 1.2rem;
					color:#d00;
				}


			}
			.flow-instance{
				margin: 10px 15px 10px 140px;
				overflow: hidden;
				background: url(../images/stripe_dot_blue.png);
				h4{
					display: block;
					background: #72acda;
					width:12rem;
					padding: 5px 10px;
					margin: 10px;
					color:#fff;
				}
				ul{
					margin: 10px 20px 10px 40px;
					li{
						overflow: visible;
						padding: 5px 0;
					}
				}
			}
		}
		& > li{
			background: url(../images/flow-title_line.png) repeat-y;
		}
	}
	h3{
		width: 110px;
		img{
			float: left;

		}
	}
}

.sitemap-list{
	ul{
		margin: 30px 45px;
		list-style: none;
		width: 80%;
		li{
			line-height: 2;
			background: url("../images/arrow-blue01.png") no-repeat scroll 10px 50%;
			padding: 10px 0 10px 45px;
			border-bottom: 1px #666 dotted;
			a:hover{
				color:#f00;
			}
		}
	}
}

.mfp_thanks{
	margin: 30px;
	font-size: 16px;
	font-size: 1.6rem;
	line-height: 1.5;
	background: #EFEFEF;
	padding: 10px 15px;
}


/* button */

a.button_blue{
	display: block;
	border: #3c95d6 1px solid;
	border-radius: 6px;
	line-height: 2.3;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(1.00, #e6f6ff), color-stop(0.54, #c6ecff), color-stop(0.50, #def5ff), color-stop(0.00, #fff));
background: -webkit-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
background: -moz-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
background: -o-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
background: -ms-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
background: linear-gradient(to bottom, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
text-decoration: none;
&:hover{
background: -webkit-gradient(linear, left top, left bottom, color-stop(1.00, #feffe6), color-stop(0.54, #fff8c6), color-stop(0.50, #ffffde), color-stop(0.00, #fff));
	background: -webkit-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
background: -moz-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
background: -o-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
background: -ms-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
background: linear-gradient(to bottom, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
}
}

a.button_blue_arrow-r{
	margin: 5px 20px;
	display: block;
	border: #3c95d6 1px solid;
	border-radius: 6px;
	line-height: 2.3;
	padding: 0 20px 0 20px;
	background: #def5ff;
background: -webkit-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
background: -moz-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
background: -o-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
background: -ms-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
background: linear-gradient(to bottom, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
text-decoration: none;
		box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
		-moz-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
		-webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
img.arrow-r{
	vertical-align: middle;
	margin: -3px 0 0 10px;
}
&:hover{
	background: #fff8c6;
	background: -webkit-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
background: -moz-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
background: -o-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
background: -ms-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
background: linear-gradient(to bottom, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
}
}

.txt-s{
	font-size: 12px;
	font-size: 1.2rem;
}
.txt-red{
	color:#d00;
}

.imgcenter{
	width: 100%;
	text-align: center;
}

// 追加

h2.border-bottom_blue{
	font-size: 3.2rem;
	font-weight: bold;
	border-bottom: 7px #0054a9 solid;
	padding: 5px 16px;
}
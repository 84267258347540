@charset "UTF-8";
/************************
交通事故
Edit by sass
last up data:2016-02-16
************************/
/*
html5doctor.com Reset Stylesheet
v1.6.1
Last Updated: 2010-09-17
Author: Richard Clark - http://richclarkdesign.com
Twitter: @rich_clark
*/
html, body, div, span, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
abbr, address, cite, code,
del, dfn, em, img, ins, kbd, q, samp,
small, strong, sub, sup, var,
b, i,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

body {
  line-height: 1;
}

article, aside, canvas, details, figcaption, figure,
footer, header, hgroup, menu, nav, section, summary {
  display: block;
}

nav ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: "";
  content: none;
}

a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

/* change colours to suit your needs */
ins {
  background-color: #ff9;
  color: #000;
  text-decoration: none;
}

/* change colours to suit your needs */
mark {
  background-color: #ff9;
  color: #000;
  font-style: italic;
  font-weight: bold;
}

del {
  text-decoration: line-through;
}

abbr[title], dfn[title] {
  border-bottom: 1px dotted;
  cursor: help;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

/* change border colour to suit your needs */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #cccccc;
  margin: 1em 0;
  padding: 0;
}

input, select {
  vertical-align: middle;
}

h1, h2, h3, h4, h5, h6 {
  font-weight: normal;
}

/* header */
#header {
  width: 960px;
  height: 96px;
  display: block;
  margin: 0 auto;
}
#header h1 {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 22px;
  width: 453px;
  padding: 0 0 0 17px;
}
#header img.sitetitle {
  float: left;
}
#header .header-contact {
  width: 480px;
  height: 70px;
  margin: -5px 0 0 0;
  float: right;
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.2;
}
#header .header-contact p span {
  font-size: 1rem;
}
#header .header-contact ul {
  margin: -10px 0 0 0;
}
#header .header-contact ul li {
  float: left;
  list-style: none;
}

body#home h1 {
  line-height: 1;
  width: auto;
  padding: 10px 0 0;
}
body#home .header-contact {
  margin: 0;
  padding: 5px 0 0;
}

/*** common ************************/
body#home, body#main-contents {
  background: url(../images/back.jpg) repeat-x;
}

body#other-contents {
  background: url(../images/back_other.jpg) repeat-x;
}

html {
  font-size: 62.5%;
}

a {
  color: #004782;
}

a:visited {
  color: #8c006d;
}

.floatl {
  float: left;
}

.floatr {
  float: right;
}

.block {
  overflow: hidden;
}

.clear {
  clear: both;
}

/* Gnavi */
#gnavi {
  clear: both;
  width: 960px;
  height: 46px;
  margin: 0 auto;
}
#gnavi ul {
  list-style: none;
}
#gnavi ul li {
  float: left;
}
#gnavi ul li a {
  text-decoration: none;
  color: #fff;
  font-size: 16px;
  line-height: 46px;
  display: block;
  padding: 0 20px;
}
#gnavi ul li a:hover {
  background: url(../images/gn-back.png) repeat-x;
}

/* main-img */
#main-img {
  width: 960px;
  margin: 0 auto;
  display: block;
  height: 442px;
  position: relative;
}
#main-img img.main-text01 {
  position: absolute;
  top: 10px;
  left: 5px;
}
#main-img img.main-text02 {
  position: absolute;
  left: 17px;
  top: 135px;
}
#main-img img.main-img02 {
  position: absolute;
  bottom: 0;
  right: 0;
}
#main-img #main-img-point {
  width: 540px;
  height: 260px;
  background: url(../images/main-img-point-back.png) no-repeat;
  position: absolute;
  bottom: 0;
}
#main-img #main-img-point ul.point {
  position: relative;
  list-style: none;
  margin: 0px 0 0 39px;
}
#main-img #main-img-point ul.point li {
  float: left;
  padding: 0 10px 6px 0;
}
#main-img #main-img-point span {
  clear: both;
  display: block;
  padding: 4px 15px 0;
  text-align: right;
}
#main-img #main-img-point .point-imgA img {
  width: 100%;
}
#main-img #main-img-point .point-imgA .point-listA {
  list-style: none;
  margin: 0 auto;
  padding: 0;
}

.wrapper {
  width: 960px;
  margin: 30px auto;
  font-size: 14px;
}
.wrapper .content-wrapper {
  width: 720px;
  float: left;
  margin: 0;
}

#home .content-pickup {
  margin: -15px auto 36px auto;
}
#home .content-pickup h2 {
  padding: 2px 0 0 0;
  border-top: 1px #0054a9 solid;
}
#home .content-pickup .pickup-wrapper {
  background: url(../images/check-gray-02.png);
  overflow: hidden;
  border: 1px #999 solid;
  margin: 7px auto;
  padding: 10px;
}
#home .content-pickup .pickup-wrapper ul.list01 > li {
  width: 210px;
  background: #fff;
  float: left;
  margin: 10px;
}
#home .content-pickup .pickup-wrapper ul.list01 > li .h3title {
  border: 1px #fff solid;
  background: #ebe6c4;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1, #e7e3be), color-stop(0, #faf7e0));
  background: -webkit-linear-gradient(#faf7e0, #e7e3be);
  background: -moz-linear-gradient(#faf7e0, #e7e3be);
  background: -o-linear-gradient(#faf7e0, #e7e3be);
  background: -ms-linear-gradient(#faf7e0, #e7e3be);
  background: linear-gradient(#faf7e0, #e7e3be);
}
#home .content-pickup .pickup-wrapper ul.list01 > li .h3title h3 {
  border-left: 7px #d00 solid;
  margin: 7px 10px;
  line-height: 1.2;
  padding: 0 0 0 10px;
}
#home .content-pickup .pickup-wrapper ul.list01 > li h3 {
  font-size: 18px;
  font-size: 1.8rem;
  font-weight: bold;
  color: #444;
}
#home .content-pickup .pickup-wrapper .list02 {
  clear: both;
}
#home .content-pickup .pickup-wrapper .list02 > li {
  background: #cbd8e6;
  width: 320px;
  float: left;
  border: 1px #fff solid;
  margin: 10px 12px;
}
#home .content-pickup .pickup-wrapper .list02 > li h3 {
  line-height: 2;
  float: left;
  font-weight: bold;
  font-size: 18px;
  font-size: 1.8rem;
  color: #444;
  width: 28%;
  margin: 7px 5px;
  padding-left: 13px;
  border-left: 12px #d00 solid;
}
#home .content-pickup .pickup-wrapper ul {
  list-style: none;
}
#home .content-pickup .pickup-wrapper ul li {
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}
#home .content-pickup .pickup-wrapper ul p {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 1.3;
  padding: 10px;
}
#home .content-pickup li a {
  text-decoration: none;
}
#home .content-pickup li a:hover {
  text-decoration: underline;
}
#home .content-pickup p.lead {
  font-size: 20px;
  font-size: 2rem;
  line-height: 1.3;
  margin: 1rem 0 0;
  padding: 1rem 1.5rem;
  border: 2px #d00 solid;
}

#home .content-negotiate {
  margin: 50px auto 30px;
}
#home .content-negotiate p.midashi {
  border-left: #666 10px solid;
  margin: 15px 0 0px 10px;
  line-height: 1.4;
  font-size: 24px;
  font-size: 2.4rem;
  padding: 5px 0 5px 20px;
  font-weight: bold;
}
#home .content-negotiate .negotiate-img {
  margin: 15px 0 15px 25px;
  border: 1px #999 solid;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1, #f3f3f3), color-stop(0, #fff));
  background: -webkit-linear-gradient(#fff, #f3f3f3);
  background: -moz-linear-gradient(#fff, #f3f3f3);
  background: -o-linear-gradient(#fff, #f3f3f3);
  background: -ms-linear-gradient(#fff, #f3f3f3);
  background: linear-gradient(#fff, #f3f3f3);
}
#home .content-negotiate .negotiate-img ul {
  list-style: none;
  padding: 12px 0 3px 30px;
  overflow: hidden;
}
#home .content-negotiate .negotiate-img ul li {
  float: left;
}
#home .content-negotiate .negotiate-img .negotiate-img-note {
  padding: 0 0 12px 0;
  margin-left: 30px;
  font-size: 1.2rem;
}
#home .content-negotiate img.negotiate-img04 {
  float: left;
  padding: 0 25px;
}
#home .content-negotiate p {
  line-height: 1.4;
  padding: 12px;
}
#home .content-negotiate .negotiate-box-instance {
  clear: both;
  overflow: hidden;
  margin: 30px 0 15px 10px;
}
#home .content-negotiate .negotiate-box-instance h3.blue-line01 {
  background: url(../images/blue-line01.png) no-repeat left bottom;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 2.5;
  padding: 5px 0 5px 20px;
  font-weight: bold;
}
#home .content-negotiate .negotiate-box-instance ul {
  margin: 15px 25px;
  list-style: none;
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -webkit-flexbox;
  display: -moz-flexbox;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: -moz-flex;
  display: flex;
  -webkit-flex-pack: distribute;
  -moz-flex-pack: distribute;
  -ms-flex-pack: distribute;
  -webkit-justify-content: space-around;
  -moz-justify-content: space-around;
  justify-content: space-around;
  -webkit-box-lines: multiple;
  -moz-box-lines: multiple;
  -webkit-flex-wrap: wrap;
  -moz-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
#home .content-negotiate .negotiate-box-instance ul li {
  width: 48%;
  background: url(../images/back-gray.png) repeat-x;
  width: 310px;
  border: 1px #999 solid;
  margin: 5px 0px 12px 18px;
}
#home .content-negotiate .negotiate-box-instance ul li .instance-header {
  padding: 10px;
  line-height: 1.3;
}
#home .content-negotiate .negotiate-box-instance ul li .instance-header img.icon-user {
  float: left;
  padding: 0 10px 0 0;
}
#home .content-negotiate .negotiate-box-instance ul li table {
  width: auto;
  margin: 5px 10px 10px;
  padding: 0;
  border-collapse: collapse;
  border: 1px #999 solid;
  font-size: 12px;
  font-size: 1.2rem;
}
#home .content-negotiate .negotiate-box-instance ul li table th {
  width: 50%;
  border-collapse: collapse;
  border: 1px #999 solid;
  vertical-align: middle;
  font-weight: normal;
  padding-left: 5px;
}
#home .content-negotiate .negotiate-box-instance ul li table th.fth {
  background: #ccc;
}
#home .content-negotiate .negotiate-box-instance ul li table th.ath {
  background: #c1d3e3;
}
#home .content-negotiate .negotiate-box-instance ul li table th.totalth {
  font-size: 14px;
  font-size: 1.4rem;
  color: #d00;
}
#home .content-negotiate .negotiate-box-instance ul li table th.result {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  background: #0089c9;
  color: #fff;
  border-right: 1px #eee solid;
}
#home .content-negotiate .negotiate-box-instance ul li table td {
  border-collapse: collapse;
  border-bottom: 1px #999 solid;
  padding: 5px;
  white-space: nowrap;
  text-align: right;
}
#home .content-negotiate .negotiate-box-instance ul li table td.totalth {
  font-size: 14px;
  font-size: 1.4rem;
  color: #d00;
}
#home .content-negotiate .negotiate-box-instance ul li table td.result {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
  background: #0089c9;
  color: #fff;
}

.tokuyaku-banner {
  height: 310px;
  background: url(../images/tokuyaku-home-img.png);
  position: relative;
  overflow: hidden;
  margin-top: -20px;
}
.tokuyaku-banner img.text01 {
  position: absolute;
  top: 85px;
  right: 12px;
}
.tokuyaku-banner > .link {
  position: absolute;
  right: 15px;
  bottom: 10px;
}

.info-contact-box-01 {
  overflow: hidden;
  padding: 10px;
  border: 4px #01508f solid;
}
.info-contact-box-01 > p {
  font-weight: bold;
  line-height: 1.5;
  font-size: 16px;
  font-size: 1.6rem;
}
.info-contact-box-01 > p span {
  font-size: 1.1rem;
}
.info-contact-box-01 .mail {
  float: left;
}
.info-contact-box-01 .tel {
  float: left;
}

.note01 {
  display: block;
  text-align: right;
  line-height: 1.4;
  font-size: 1.2rem;
  padding: 0.5rem 1rem;
}

#other-contents .negotiate-box-instance {
  margin: 20px auto;
  font-size: 16px;
  font-size: 1.6rem;
}
#other-contents .negotiate-box-instance ul {
  list-style: none;
}
#other-contents .negotiate-box-instance ul li {
  margin: 5px 0 40px;
}
#other-contents .negotiate-box-instance ul li .instance-wrapper {
  background: url("../images/back-gray.png") repeat-x scroll 0 0 rgba(0, 0, 0, 0);
  border: 1px solid #999;
  margin: 10px 0 15px 40px;
}
#other-contents .negotiate-box-instance ul li .instance-wrapper .instance-header {
  margin: 10px 15px;
  border-bottom: 1px #999 dotted;
}
#other-contents .negotiate-box-instance ul li .instance-wrapper .instance-header img {
  vertical-align: middle;
  padding: 0 15px 5px 0;
}
#other-contents .negotiate-box-instance ul li .instance-wrapper table {
  margin: 15px 60px;
  border: 1px #999 solid;
  border-collapse: collapse;
  width: 80%;
  font-size: 1.4rem;
}
#other-contents .negotiate-box-instance ul li .instance-wrapper table th {
  padding: 10px;
  border-collapse: collapse;
  border: 1px #999 solid;
}
#other-contents .negotiate-box-instance ul li .instance-wrapper table th.fth {
  background: #ccc;
}
#other-contents .negotiate-box-instance ul li .instance-wrapper table th.ath {
  background: #c1d3e3;
}
#other-contents .negotiate-box-instance ul li .instance-wrapper table th.totalth {
  color: #d00;
}
#other-contents .negotiate-box-instance ul li .instance-wrapper table th.result {
  font-weight: bold;
  background: #0089c9;
  color: #fff;
}
#other-contents .negotiate-box-instance ul li .instance-wrapper table td {
  padding: 10px;
  border-collapse: collapse;
  border: 1px #999 solid;
  white-space: nowrap;
  text-align: right;
}
#other-contents .negotiate-box-instance ul li .instance-wrapper table td.totalth {
  color: #d00;
  font-size: 1.6rem;
  font-weight: bold;
}
#other-contents .negotiate-box-instance ul li .instance-wrapper table td.result {
  font-size: 20px;
  font-size: 2rem;
  font-weight: bold;
  background: #0089c9;
  color: #fff;
}
#other-contents .negotiate-box-instance ul li .instance-wrapper p {
  line-height: 1.3;
  margin: 15px 70px;
  font-size: 14px;
  font-size: 1.4rem;
}
#other-contents .negotiate-box-instance ul li ul.note-ul {
  margin: 5px 15px 5px 10%;
  padding: 0;
}
#other-contents .negotiate-box-instance ul li ul.note-ul li {
  margin: 5px 15px;
  padding: 5px;
  list-style-type: square;
}
#other-contents .negotiate-box-instance ul li .commentBox {
  margin: 15px 60px;
  border: double 3px #01508f;
  width: 80%;
}
#other-contents .negotiate-box-instance ul li .commentBox p {
  margin: 10px auto;
  padding: 0 10px;
  line-height: 1.5;
}
#other-contents .negotiate-box-instance ul li .commentBox p span {
  background-color: #01508f;
  color: #fff;
  border-radius: 4px;
  padding: 2px 5px;
}

#home .content-merit .reason-note {
  margin: 15px 35px;
  line-height: 1.4;
}
#home .content-merit .reason-note p {
  padding: 5px;
}
#home .content-merit .reason-note p span {
  font-size: 16px;
  font-size: 1.6rem;
  font-weight: bold;
}
#home .content-merit h3 {
  font-weight: bold;
  margin: 10px 0 10px 10px;
  line-height: 48px;
  font-size: 18px;
  font-size: 1.8rem;
  border: 1px #3c95d6 solid;
  background: -webkit-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
  background: -moz-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
  background: -o-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
  background: -ms-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
  background: linear-gradient(to bottom, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
}
#home .content-merit h3 img {
  vertical-align: middle;
  padding-right: 10px;
}
#home .content-merit ol {
  list-style: none;
}
#home .content-merit ol li {
  margin: 0 0 0 25px;
}
#home .content-merit ol li h4 {
  background: url(../images/merit-blueline.png) no-repeat;
  line-height: 45px;
  margin: 5px 0;
  font-size: 18px;
  font-size: 1.8rem;
  padding: 7px 0 0 0;
}
#home .content-merit ol li h4 span {
  display: block;
  float: left;
  text-align: center;
  width: 40px;
  color: #fff;
}
#home .content-merit ol li .note-block {
  background: url(../images/merit-shade.png) no-repeat right bottom;
  min-height: 120px;
  overflow: hidden;
}
#home .content-merit ol li .note-block .note {
  min-height: 108px;
  margin: 0px 10px 15px 0;
  background: url(../images/check-gray.png) right bottom;
  border: 1px #cecece solid;
  overflow: hidden;
}
#home .content-merit ol li .note-block .note p {
  float: left;
  margin: 20px 0 20px 30px;
  width: 560px;
  line-height: 1.4;
}
#home .content-merit ol li .note-block .note p span {
  color: #d00;
}
#home .content-merit ol li .note-block .note .arrow {
  margin: 20px 15px 0 0px;
  width: 60px;
  float: right;
  font-size: 12px;
  font-size: 1.2rem;
}

#home .content-pr {
  margin: 36px auto 10px;
}
#home .content-pr .pr-note {
  padding: 15px 40px;
  line-height: 1.4;
}
#home .content-pr .pr-note > p {
  font-size: 18px;
  font-size: 1.8rem;
}
#home .content-pr .pr-note p.txt-s {
  font-size: 12px;
  font-size: 1.2rem;
  padding: 0.5rem 1.5rem 1.5rem;
  float: left;
}
#home .content-pr .pr-note .pr-note-blue-ul {
  overflow: hidden;
  margin: 0px 0px;
  background: url(../images/paper-blue.png);
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}
#home .content-pr .pr-note .pr-note-blue-ul ul {
  font-size: 24px;
  font-size: 2.4rem;
  list-style: none;
  width: 440px;
  margin: 0 15px 0 170px;
  padding: 10px 0;
  line-height: 1.4;
}
#home .content-pr .pr-note .pr-note-blue-ul ul li {
  padding: 10px 0;
  font-weight: bold;
}
#home .content-pr .pr-note .pr-note-blue-ul ul li > span {
  color: #0054a9;
}
#home .content-pr .pr-note .pr-img01 {
  margin-top: -200px;
  float: left;
}

#home .content-info, #main-contents .content-info {
  margin: 30px auto 0;
}
#home .content-info .info-note, #main-contents .content-info .info-note {
  padding: 15px 40px;
  line-height: 1.4;
}
#home .content-info .info-note > p, #main-contents .content-info .info-note > p {
  font-size: 18px;
  font-size: 1.8rem;
}
#home .content-info .info-note-blue, #main-contents .content-info .info-note-blue {
  overflow: hidden;
  padding: 15px 0 0;
  background: #e2f5ff;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.88, #def3ff), color-stop(0, #f3fbff));
  background: -webkit-linear-gradient(top, #f3fbff 0%, #def3ff 88%);
  background: -moz-linear-gradient(top, #f3fbff 0%, #def3ff 88%);
  background: -o-linear-gradient(top, #f3fbff 0%, #def3ff 88%);
  background: -ms-linear-gradient(top, #f3fbff 0%, #def3ff 88%);
  background: linear-gradient(to bottom, #f3fbff 0%, #def3ff 88%);
}
#home .content-info .info-note-blue .info-note-blue-ul, #main-contents .content-info .info-note-blue .info-note-blue-ul {
  margin: 0px 35px;
  background: url(../images/paper-blue.png);
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}
#home .content-info .info-note-blue .info-note-blue-ul ul, #main-contents .content-info .info-note-blue .info-note-blue-ul ul {
  font-size: 18px;
  font-size: 1.8rem;
  list-style: none;
  width: 420px;
  margin: 0 25px;
  padding: 15px 0;
  line-height: 1.4;
}
#home .content-info .info-note-blue .info-note-blue-ul ul li, #main-contents .content-info .info-note-blue .info-note-blue-ul ul li {
  padding: 10px 0;
}
#home .content-info .info-note-blue .info-img01, #main-contents .content-info .info-note-blue .info-img01 {
  vertical-align: bottom;
}
#home .content-info .info-note-blue .info-note2, #main-contents .content-info .info-note-blue .info-note2 {
  margin: 25px 0 25px 35px;
  width: 415px;
  line-height: 1.3;
}
#home .content-info .info-note-blue .info-note2 p, #main-contents .content-info .info-note-blue .info-note2 p {
  padding: 5px;
  font-size: 18px;
  font-size: 1.8rem;
}
#home .content-info .info-note-blue .info-contact-box, #main-contents .content-info .info-note-blue .info-contact-box {
  width: 688px;
  margin: 30px 0 10px 25px;
}
#home .content-info .info-note-blue .info-contact-box > .tel, #main-contents .content-info .info-note-blue .info-contact-box > .tel {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.3;
  width: 401px;
}
#home .content-info .info-note-blue .info-contact-box > .tel span, #main-contents .content-info .info-note-blue .info-contact-box > .tel span {
  font-size: 1.2rem;
}
#home .content-info .info-note-blue .info-contact-box > .tel p, #main-contents .content-info .info-note-blue .info-contact-box > .tel p {
  text-align: right;
  padding-right: 20px;
}
#home .content-info .info-note-blue .info-contact-box > .mail, #main-contents .content-info .info-note-blue .info-contact-box > .mail {
  float: right;
  text-align: center;
  margin: -435px 0 20px 0;
}

.return-wrapper {
  display: block;
  clear: both;
}
.return-wrapper a {
  margin: 5px 0;
  float: right;
  border: 1px #005497 solid;
  line-height: 2.5;
  text-decoration: none;
  padding: 3px 10px 3px 35px;
  color: #005497;
  background: url(../images/arrow-up_off.png) no-repeat 10px center;
}
.return-wrapper a:hover {
  background: url(../images/arrow-up_on.png) no-repeat 10px center, #005497;
  color: #fff;
}
.return-wrapper img {
  vertical-align: middle;
  padding: 0 5px;
}

/* other page */
.breadcrumbs {
  font-size: 12px;
  font-size: 1.2rem;
  line-height: 2;
  overflow: hidden;
}
.breadcrumbs ul, .breadcrumbs ol {
  list-style: none;
}
.breadcrumbs ul li, .breadcrumbs ol li {
  display: inline;
}
.breadcrumbs ul li a, .breadcrumbs ol li a {
  padding: 0.3rem 0.5rem;
}
.breadcrumbs ul li a:hover, .breadcrumbs ol li a:hover {
  color: #f00;
}
.breadcrumbs ul li::before, .breadcrumbs ol li::before {
  content: " > ";
}
.breadcrumbs ul li:first-child::before, .breadcrumbs ol li:first-child::before {
  content: "";
}

body#other-contents .content {
  margin: 7px auto 30px;
}
body#other-contents .content .h2title {
  background: url(../images/h2title_back.png) no-repeat;
  line-height: 70px;
  color: #fff;
  font-weight: bold;
  font-size: 22px;
  font-size: 2.2rem;
  padding: 0 0 0 15px;
}
body#other-contents .content h3.h3title {
  clear: both;
  font-weight: bold;
  margin: 30px 0 10px 10px;
  line-height: 48px;
  font-size: 18px;
  font-size: 1.8rem;
  border: 1px #3c95d6 solid;
  padding: 0 0 0 30px;
  background: url(../images/h3-blue-img.png) no-repeat left center, -webkit-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
  background: url(../images/h3-blue-img.png) no-repeat left center, -moz-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
  background: url(../images/h3-blue-img.png) no-repeat left center, -o-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
  background: url(../images/h3-blue-img.png) no-repeat left center, -ms-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
  background: url(../images/h3-blue-img.png) no-repeat left center, linear-gradient(to bottom, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
}

.content-single .note {
  margin: 15px 25px;
  line-height: 24px;
  line-height: 2.4rem;
  background: url(../images/border-dot.png) left bottom;
}
.content-single .note p {
  margin: 0 0 24px 0;
}
.content-single .note ul, .content-single .note ol {
  margin: 0 15px 24px;
}
.content-single .note table {
  margin: 0 15px 24px;
}
.content-single .note table td {
  padding: 0 10px 0 0;
}
.content-single .note-kouishou {
  margin: 15px 0px 15px 25px;
}
.content-single .note-kouishou h4 {
  line-height: 36px;
  font-weight: bold;
  margin: 24px 0 12px 120px;
  background: #3c95d6;
  color: #fff;
  padding: 0 0 0px 15px;
  border: 1px #1b75b7 solid;
}
.content-single .note-kouishou img {
  float: left;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  margin: 0px 15px 24px 0;
}
.content-single .note-kouishou p {
  margin: 0 0 24px 130px;
}

.kouishou-link {
  margin: 30px 0 10px 10px;
  border: 3px #d8cbba solid;
  background: #fbfaed;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1, #f8f6e4), color-stop(0, #fbfaed));
  background: -webkit-linear-gradient(#fbfaed, #f8f6e4);
  background: -moz-linear-gradient(#fbfaed, #f8f6e4);
  background: -o-linear-gradient(#fbfaed, #f8f6e4);
  background: -ms-linear-gradient(#fbfaed, #f8f6e4);
  background: linear-gradient(#fbfaed, #f8f6e4);
}
.kouishou-link ul {
  list-style: none;
  padding: 20px 10px;
  overflow: hidden;
}
.kouishou-link ul li {
  padding: 5px 15px;
  float: left;
  border-right: 1px #999 solid;
}
.kouishou-link ul li a {
  padding: 3px;
}
.kouishou-link ul li a:hover {
  border-radius: 4px;
  background: #d8cbba;
}

.kouishou-list ul {
  margin: 30px 50px;
  width: 70%;
}
.kouishou-list ul li {
  padding: 10px 0;
  border-bottom: #999 1px dotted;
  line-height: 1.5;
}

.return-kouishou-nav {
  margin: 15px 0 30px 15px;
  overflow: hidden;
}
.return-kouishou-nav a {
  border: 1px #005497 solid;
  text-decoration: none;
  color: #005497;
  float: right;
  border: 1px #999 solid;
  padding: 10px 15px;
  font-size: 12px;
  font-size: 1.2rem;
}
.return-kouishou-nav a:hover {
  background: #005497;
  color: #fff;
}

.attention {
  border: 3px #d00 solid;
  margin: 30px 0 10px 10px;
  padding: 15px 25px;
  line-height: 1.5;
  font-size: 16px;
  font-size: 1.6rem;
  color: #fff;
  background: #ff0001;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0, #ff0001), color-stop(1, #8f0202));
  background: -webkit-linear-gradient(#ff0001, #8f0202);
  background: -moz-linear-gradient(#ff0001, #8f0202);
  background: -o-linear-gradient(#ff0001, #8f0202);
  background: -ms-linear-gradient(#ff0001, #8f0202);
  background: linear-gradient(#ff0001, #8f0202);
}

#dl-profile {
  margin: 30px 0 20px 20px;
}
#dl-profile dl {
  line-height: 1.5;
}
#dl-profile dl dt {
  color: #006699;
  padding: 10px 0 0 0.5rem;
  width: 13rem;
}
#dl-profile dl dd {
  border-bottom: 1px dotted #999;
  margin-top: -1.5em;
  padding: 0 0 10px 14rem;
}
#dl-profile dl dd.author_photo {
  overflow: hidden;
}
#dl-profile dl dd.author_photo span {
  float: left;
}
#dl-profile dl dd.author_photo img {
  width: 120px;
  padding: 0 0 0 7rem;
}

#contents-access {
  overflow: hidden;
}
#contents-access #mapdl {
  float: left;
  padding: 5px 35px 5px 15px;
}
#contents-access #mapdl dl {
  line-height: 1.5;
  width: 300px;
}
#contents-access #mapdl dl dt {
  background: 0 0 #efefef;
}
#contents-access #mapdl dl dd {
  padding: 5px 0 15px 10px;
}

#contents-privacy {
  font-size: 14px;
  line-height: 1.3;
}
#contents-privacy p {
  padding: 8px 15px;
}
#contents-privacy #privacy-dl {
  margin: 15px;
}
#contents-privacy #privacy-dl dt {
  border-bottom: 1px solid #ccc;
}
#contents-privacy #privacy-dl dd {
  padding: 8px 15px 20px;
}

.content-qa dl {
  margin: 20px 0 20px 15px;
}
.content-qa dl dt {
  padding: 15px 0 0 0;
  overflow: hidden;
}
.content-qa dl dt h3 {
  font-size: 16px;
  font-size: 1.6rem;
  color: #0067b3;
  padding: 5px 0 0 0;
  line-height: 1.3;
}
.content-qa dl dt img {
  float: left;
  vertical-align: middle;
  padding: 0 15px 0 0;
}
.content-qa dl dd {
  padding: 5px 0 15px;
  border-bottom: 1px #ccc solid;
}
.content-qa dl dd .answer {
  padding: 15px 0 15px 15px;
}
.content-qa dl dd .answer img {
  float: left;
  padding: 0 15px 0 0;
}
.content-qa dl dd .answer p {
  font-size: 14px;
  font-size: 1.4rem;
  line-height: 1.4;
  color: #555;
  padding-left: 60px;
}
.content-qa dl dd .answer table {
  color: #555;
  margin: 15px 15px 15px 70px;
  border: 1px #999 solid;
  border-collapse: collapse;
}
.content-qa dl dd .answer table th, .content-qa dl dd .answer table td {
  padding: 5px 10px;
  border: 1px #999 solid;
  border-collapse: collapse;
}

.trouble-list-wrapper {
  background: url(../images/fukidashi.png) no-repeat left top;
  position: relative;
  min-height: 357px;
}
.trouble-list-wrapper img.merit-img01 {
  float: right;
  vertical-align: bottom;
}
.trouble-list-wrapper ul.trouble-list {
  list-style: none;
}
.trouble-list-wrapper ul.trouble-list li {
  line-height: 1.2;
  font-size: 16px;
  font-size: 1.6rem;
}
.trouble-list-wrapper ul.trouble-list li span {
  font-weight: bold;
}
.trouble-list-wrapper ul.trouble-list li.trouble01 {
  position: absolute;
  top: 25px;
  left: 67px;
  font-size: 18px;
  font-size: 1.8rem;
}
.trouble-list-wrapper ul.trouble-list li.trouble02 {
  position: absolute;
  top: 76px;
  left: 227px;
}
.trouble-list-wrapper ul.trouble-list li.trouble03 {
  position: absolute;
  left: 53px;
  top: 125px;
}
.trouble-list-wrapper ul.trouble-list li.trouble04 {
  position: absolute;
  top: 145px;
  left: 243px;
  font-size: 24px;
  font-size: 2.4rem;
}
.trouble-list-wrapper ul.trouble-list li.trouble05 {
  position: absolute;
  top: 200px;
  left: 63px;
  font-size: 18px;
  font-size: 1.8rem;
}
.trouble-list-wrapper ul.trouble-list li.trouble06 {
  position: absolute;
  top: 237px;
  left: 276px;
}
.trouble-list-wrapper ul.trouble-list li.trouble07 {
  position: absolute;
  top: 290px;
  left: 107px;
}

.trouble-list-footer, .tokuyaku-list-footer {
  background: #0054a9;
  color: #fff;
  font-size: 20px;
  font-size: 2rem;
  padding: 20px 25px;
  line-height: 1.4;
}

.tokuyaku-list-wrapper {
  background: url(../images/tokuyaku-box-back.jpg) no-repeat left top;
  min-height: 645px;
  position: relative;
}
.tokuyaku-list-wrapper .tokuyaku-text01 {
  position: absolute;
  top: 45px;
  left: 95px;
}
.tokuyaku-list-wrapper .tokuyaku-text02 {
  position: absolute;
  top: 195px;
  left: 220px;
}
.tokuyaku-list-wrapper .tokuyaku-list {
  width: 360px;
  position: absolute;
  top: 365px;
  left: 300px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 1.4;
  list-style: none;
}
.tokuyaku-list-wrapper .tokuyaku-list li {
  padding: 10px 0;
}

.content-tokuyaku h3.h3title {
  clear: both;
  font-weight: bold;
  margin: 30px 0 10px 10px;
  line-height: 48px;
  font-size: 18px;
  font-size: 1.8rem;
  border: 1px #3c95d6 solid;
  padding: 0 0 0 30px;
  background: url(../images/h3-blue-img.png) no-repeat left center, -webkit-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
  background: url(../images/h3-blue-img.png) no-repeat left center, -moz-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
  background: url(../images/h3-blue-img.png) no-repeat left center, -o-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
  background: url(../images/h3-blue-img.png) no-repeat left center, -ms-linear-gradient(top, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
  background: url(../images/h3-blue-img.png) no-repeat left center, linear-gradient(to bottom, #fff 0%, #fff 50%, #ededed 50%, #f3f3f3 100%);
}
.content-tokuyaku .tokuyaku-box .note {
  margin: 20px 0 20px 40px;
}
.content-tokuyaku .tokuyaku-box .note p {
  padding: 10px 5px;
  line-height: 1.4;
}

#main-contents .merit-box {
  margin: 20px auto;
}
#main-contents .merit-box h3.merit-title {
  border-left: #477eb6 solid 15px;
  color: #0054a9;
  font-size: 30px;
  font-size: 3rem;
  font-weight: bold;
  padding: 10px 15px;
  line-height: 1.5;
  margin: 10px 0 10px 10px;
}
#main-contents .merit-box .merit-box-note {
  margin-left: 25px;
}
#main-contents .merit-box .merit-box-note .merit-voice {
  background: url(../images/check-gray.png);
  border: 1px #ccc solid;
  margin: 20px 0 0 0;
}
#main-contents .merit-box .merit-box-note .merit-voice img.icon {
  float: left;
  padding: 10px;
}
#main-contents .merit-box .merit-box-note .merit-voice p {
  line-height: 1.4;
  font-size: 18px;
  font-size: 1.8rem;
  margin: 15px 20px 15px 100px;
}
#main-contents .merit-box .merit-box-note .merit-voice p span {
  font-size: 14px;
  font-size: 1.4rem;
}
#main-contents .merit-box .merit-box-note .merit-h4-wrap {
  background: #01adc8;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1, #00c0df), color-stop(0.1, #02abc6));
  background: -webkit-linear-gradient(top, #02abc6 10%, #00c0df 100%);
  background: -moz-linear-gradient(top, #02abc6 10%, #00c0df 100%);
  background: -o-linear-gradient(top, #02abc6 10%, #00c0df 100%);
  background: -ms-linear-gradient(top, #02abc6 10%, #00c0df 100%);
  background: linear-gradient(to bottom, #02abc6 10%, #00c0df 100%);
  color: #fff;
  padding: 13px;
  margin: 15px 0 25px;
}
#main-contents .merit-box .merit-box-note .merit-h4-wrap span {
  border-radius: 6px;
  border: 1px #FFF solid;
  background: #006cad;
  color: #fff;
  font-size: 14px;
  font-size: 1.4rem;
  font-weight: bold;
  width: 6rem;
  display: block;
  padding: 5px 8px;
  line-height: 1.2;
  float: left;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.88, #0067a5), color-stop(0, #0085d3));
  background: -webkit-linear-gradient(top, #0085d3 0%, #0067a5 88%);
  background: -moz-linear-gradient(top, #0085d3 0%, #0067a5 88%);
  background: -o-linear-gradient(top, #0085d3 0%, #0067a5 88%);
  background: -ms-linear-gradient(top, #0085d3 0%, #0067a5 88%);
  background: linear-gradient(to bottom, #0085d3 0%, #0067a5 88%);
}
#main-contents .merit-box .merit-box-note .merit-h4-wrap h4 {
  line-height: 1.3;
  margin: 0 0 0 85px;
  font-size: 24px;
  font-size: 2.4rem;
  font-weight: bold;
}
#main-contents .merit-box .merit-box-note .merit-headline {
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.3;
  margin: 15px 0 5px 15px;
  padding: 15px;
  background: #EFEFEF;
}
#main-contents .merit-box .merit-box-note .note img {
  float: right;
  padding: 15px 10px 5px 20px;
}
#main-contents .merit-box .merit-box-note .note p {
  padding: 20px 25px;
  line-height: 1.5;
}
#main-contents .merit-box .merit-box-note .note ul {
  padding: 10px 45px;
  line-height: 1.3;
}
#main-contents .merit-box .merit-box-note .note ul li {
  padding: 5px 0;
}
#main-contents .merit-box .merit-box-note .note ul li span {
  font-weight: bold;
}

.content-single .note-voice {
  margin: 20px 0 20px 25px;
  border: 1px #ccc solid;
  padding: 24px 15px 0;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}

.attention-fee {
  margin: 20px 0;
  border: 1px #d00 solid;
  padding: 15px;
}
.attention-fee h3.attentiontitle {
  font-size: 16px;
  font-size: 1.6rem;
  margin: 5px;
  padding: 5px 10px;
  border-left: 10px #d00 solid;
}
.attention-fee p {
  line-height: 1.3;
  padding: 10px 15px;
}

.note-fee p {
  padding: 10px 25px;
  line-height: 1.3;
}
.note-fee p span {
  font-size: 24px;
  color: #d00;
}
.note-fee h4 {
  background: none repeat scroll 0 0 #3c95d6;
  border: 1px solid #1b75b7;
  color: #fff;
  font-weight: bold;
  line-height: 36px;
  margin: 24px 0 12px 15px;
  padding: 0 0 0 15px;
}

.note-fee table {
  width: calc(100% - 40px);
  margin: 35px 20px 5px;
  border-collapse: collapse;
  border: 1px #666 solid;
}
.note-fee table th, .note-fee table td {
  border-collapse: collapse;
  border: 1px #666 solid;
  vertical-align: middle;
  line-height: 1.6;
  text-align: left;
  padding: 5px 15px;
  width: 50%;
  font-weight: 500;
}
.note-fee table tr:first-child th {
  font-weight: bold;
}
.note-fee p {
  line-height: 1.6;
}
.note-fee p.spotlight {
  font-weight: bold;
}
.note-fee ul {
  list-style: none;
}
.note-fee ul li {
  line-height: 1.6;
}

.word-link {
  margin: 30px 0 10px 10px;
  border: 3px #d8cbba solid;
  background: #fbfaed;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1, #f8f6e4), color-stop(0, #fbfaed));
  background: -webkit-linear-gradient(#fbfaed, #f8f6e4);
  background: -moz-linear-gradient(#fbfaed, #f8f6e4);
  background: -o-linear-gradient(#fbfaed, #f8f6e4);
  background: -ms-linear-gradient(#fbfaed, #f8f6e4);
  background: linear-gradient(#fbfaed, #f8f6e4);
}
.word-link table {
  margin: 15px;
}
.word-link table th {
  white-space: nowrap;
  font-weight: normal;
  padding: 10px;
}
.word-link table td {
  border-bottom: 1px #d8cbba solid;
}
.word-link table td ul {
  list-style: none;
}
.word-link table td ul li {
  float: left;
  padding: 0 10px;
}
.word-link table td ul li a:hover {
  background: none repeat scroll 0 0 #d8cbba;
  border-radius: 4px;
}

.word-list ul {
  margin: 30px 50px;
  width: 90%;
  list-style: none;
}
.word-list ul li {
  padding: 10px 0;
  border-bottom: #999 1px dotted;
  line-height: 1.5;
}
.word-list ul li h4 {
  background: #EFEFEF;
  font-weight: bold;
  padding: 5px 10px;
  margin: 5px 0 10px;
}
.word-list ul li p {
  padding-left: 15px;
}

.flow-box {
  margin: 20px 0 20px 20px;
}
.flow-box > ol {
  list-style: none;
}
.flow-box > ol li {
  overflow: hidden;
}
.flow-box > ol li p {
  margin: 10px 15px 10px 140px;
  font-size: 16px;
  line-height: 1.3;
}
.flow-box > ol li p span {
  color: #d00;
}
.flow-box > ol li p span.tyui {
  font-size: 12px;
  font-size: 1.2rem;
  color: #d00;
}
.flow-box > ol li .flow-instance {
  margin: 10px 15px 10px 140px;
  overflow: hidden;
  background: url(../images/stripe_dot_blue.png);
}
.flow-box > ol li .flow-instance h4 {
  display: block;
  background: #72acda;
  width: 12rem;
  padding: 5px 10px;
  margin: 10px;
  color: #fff;
}
.flow-box > ol li .flow-instance ul {
  margin: 10px 20px 10px 40px;
}
.flow-box > ol li .flow-instance ul li {
  overflow: visible;
  padding: 5px 0;
}
.flow-box > ol > li {
  background: url(../images/flow-title_line.png) repeat-y;
}
.flow-box h3 {
  width: 110px;
}
.flow-box h3 img {
  float: left;
}

.sitemap-list ul {
  margin: 30px 45px;
  list-style: none;
  width: 80%;
}
.sitemap-list ul li {
  line-height: 2;
  background: url("../images/arrow-blue01.png") no-repeat scroll 10px 50%;
  padding: 10px 0 10px 45px;
  border-bottom: 1px #666 dotted;
}
.sitemap-list ul li a:hover {
  color: #f00;
}

.mfp_thanks {
  margin: 30px;
  font-size: 16px;
  font-size: 1.6rem;
  line-height: 1.5;
  background: #EFEFEF;
  padding: 10px 15px;
}

/* button */
a.button_blue {
  display: block;
  border: #3c95d6 1px solid;
  border-radius: 6px;
  line-height: 2.3;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1, #e6f6ff), color-stop(0.54, #c6ecff), color-stop(0.5, #def5ff), color-stop(0, #fff));
  background: -webkit-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
  background: -moz-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
  background: -o-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
  background: -ms-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
  background: linear-gradient(to bottom, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
  text-decoration: none;
}
a.button_blue:hover {
  background: -webkit-gradient(linear, left top, left bottom, color-stop(1, #feffe6), color-stop(0.54, #fff8c6), color-stop(0.5, #ffffde), color-stop(0, #fff));
  background: -webkit-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
  background: -moz-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
  background: -o-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
  background: -ms-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
  background: linear-gradient(to bottom, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
}

a.button_blue_arrow-r {
  margin: 5px 20px;
  display: block;
  border: #3c95d6 1px solid;
  border-radius: 6px;
  line-height: 2.3;
  padding: 0 20px 0 20px;
  background: #def5ff;
  background: -webkit-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
  background: -moz-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
  background: -o-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
  background: -ms-linear-gradient(top, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
  background: linear-gradient(to bottom, #fff 0%, #def5ff 50%, #c6ecff 50%, #e6f6ff 100%);
  text-decoration: none;
  box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
  -webkit-box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.25);
}
a.button_blue_arrow-r img.arrow-r {
  vertical-align: middle;
  margin: -3px 0 0 10px;
}
a.button_blue_arrow-r:hover {
  background: #fff8c6;
  background: -webkit-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
  background: -moz-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
  background: -o-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
  background: -ms-linear-gradient(top, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
  background: linear-gradient(to bottom, #fff 0%, #ffffde 50%, #fff8c6 50%, #feffe6 100%);
}

.txt-s {
  font-size: 12px;
  font-size: 1.2rem;
}

.txt-red {
  color: #d00;
}

.imgcenter {
  width: 100%;
  text-align: center;
}

h2.border-bottom_blue {
  font-size: 3.2rem;
  font-weight: bold;
  border-bottom: 7px #0054a9 solid;
  padding: 5px 16px;
}

/* sidemenu */
#sidemenu {
  width: 200px;
  margin: 35px auto;
  float: right;
}
#sidemenu ul.sidemenu-list {
  list-style: none;
}
#sidemenu ul.sidemenu-list > li {
  padding-bottom: 15px;
}
#sidemenu .sidemenu-qa {
  border: 1px #999999 solid;
  background: #f5f5f5;
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0.88, #eaeaea), color-stop(0, #fefefe));
  background: -webkit-linear-gradient(top, #fefefe 0%, #eaeaea 88%);
  background: -moz-linear-gradient(top, #fefefe 0%, #eaeaea 88%);
  background: -o-linear-gradient(top, #fefefe 0%, #eaeaea 88%);
  background: -ms-linear-gradient(top, #fefefe 0%, #eaeaea 88%);
  background: linear-gradient(to bottom, #fefefe 0%, #eaeaea 88%);
}
#sidemenu .sidemenu-qa h3 {
  padding: 3px 0 0 15px;
  font-size: 18px;
  font-size: 1.8rem;
  line-height: 35px;
  border-bottom: #f76e00 4px solid;
  background: url(../images/qa-side-img01.png) no-repeat 95% bottom;
}
#sidemenu .sidemenu-qa ul.sideqa-list {
  padding: 5px 10px 10px;
}
#sidemenu .sidemenu-qa ul.sideqa-list li {
  font-size: 12px;
  font-size: 1.2rem;
  border-bottom: 1px #999 dotted;
  background: url(../images/q-icon.png) no-repeat left 13px;
  line-height: 1.2;
  padding: 12px 0 12px 30px;
}
#sidemenu .sidemenu-qa .qa-pagelink {
  font-size: 12px;
  font-size: 1.2rem;
  padding: 5px 10px 10px;
  text-align: right;
}
#sidemenu .sidenavi-title {
  background: #005497;
  color: #fff;
  font-size: 18px;
  font-size: 1.8rem;
  padding: 7px 15px;
}
#sidemenu .sidenavi-li {
  border: 1px #ccc solid;
}
#sidemenu .sidenavi-li li {
  border-top: dotted 1px #ccc;
  font-size: 16px;
  font-size: 1.6rem;
  background: url(../images/arrow-blue01.png) no-repeat 10px 50%;
  background: url(../images/arrow-blue01.png) no-repeat 10px 50%, -webkit-gradient(linear, left top, left bottom, color-stop(1, #f3f3f3), color-stop(0, #fff));
  background: url(../images/arrow-blue01.png) no-repeat 10px 50%, -webkit-linear-gradient(top, #fff 0%, #f3f3f3 100%);
  background: url(../images/arrow-blue01.png) no-repeat 10px 50%, -moz-linear-gradient(top, #fff 0%, #f3f3f3 100%);
  background: url(../images/arrow-blue01.png) no-repeat 10px 50%, -o-linear-gradient(top, #fff 0%, #f3f3f3 100%);
  background: url(../images/arrow-blue01.png) no-repeat 10px 50%, -ms-linear-gradient(top, #fff 0%, #f3f3f3 100%);
  background: url(../images/arrow-blue01.png) no-repeat 10px 50%, linear-gradient(to bottom, #fff 0%, #f3f3f3 100%);
}
#sidemenu .sidenavi-li li:first-child {
  border-top: none;
}
#sidemenu .sidenavi-li li a {
  display: block;
  padding: 10px 5px 10px 35px;
  text-decoration: none;
}
#sidemenu .sidenavi-li li a:hover {
  background: url(../images/arrow-blue01.png) no-repeat 10px 50%, #EFEFEF;
  text-decoration: underline;
}

/* footer */
#footer {
  clear: both;
  background: #005497;
  min-width: 960px;
}
#footer #footer-wrap {
  clear: both;
  width: 960px;
  margin: 0 auto;
}
#footer #footer-wrap .footer-link {
  overflow: hidden;
}
#footer #footer-wrap .footer-link ul {
  font-size: 12px;
  font-size: 1.2rem;
  list-style: none;
  margin: 10px;
}
#footer #footer-wrap .footer-link ul li {
  padding: 10px;
  float: left;
}
#footer #footer-wrap .footer-link ul a {
  color: #fff;
  text-decoration: none;
}
#footer #footer-wrap .footer-link ul a:after {
  content: " >";
}
#footer #footer-wrap .footer-link ul a:hover {
  text-decoration: underline;
}
#footer #footer-wrap .flink {
  width: 960px;
  margin: 0 auto;
  padding: 3rem 0 1.5rem;
}
#footer #footer-wrap .flink p {
  color: #fff;
  font-size: 12px;
  font-size: 1.2rem;
  margin-left: 2rem;
}
#footer #footer-wrap .flink ul {
  list-style: none;
  overflow: hidden;
  font-size: 12px;
  font-size: 1.2rem;
  margin: 1.5rem 3rem;
}
#footer #footer-wrap .flink ul li {
  float: left;
  margin: 0.5rem 0;
}
#footer #footer-wrap .flink ul li a {
  display: block;
  color: #fff;
  text-decoration: none;
  padding: 0.3rem 1rem;
  border-left: 1px #eee solid;
}
#footer #footer-wrap .flink ul li a:hover {
  text-decoration: underline;
}
#footer #footer-wrap .flink ul li:last-child a {
  border-right: 1px #eee solid;
}
#footer #footer-wrap .footer-title {
  padding: 10px 0;
  display: block;
  clear: both;
  text-align: right;
}
#footer #copyright-wrapper {
  background: #fff;
  width: 100%;
  text-align: center;
  padding: 5px 0;
  font-size: 12px;
  font-size: 1.2rem;
}

#footer #footer-wrap #sp-pc_switch {
  display: none;
}

html.mobile #footer #footer-wrap {
  position: relative;
}
html.mobile #footer #footer-wrap .footer-title {
  position: absolute;
  bottom: 10px;
  right: 20px;
}
html.mobile #footer #footer-wrap #sp-pc_switch {
  width: 49%;
  display: block;
  margin: 20px 3%;
}
html.mobile #footer #footer-wrap #sp-pc_switch a {
  background: #fff;
  font-size: 1.6rem;
  padding: 10px 15px;
  display: inline-block;
}
html.mobile #footer #footer-wrap #sp-pc_switch a i {
  vertical-align: middle;
  padding: 0 5px;
}

* {
  font-family: Helvetica, "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro W3", メイリオ, Meiryo, "ＭＳ Ｐゴシック", sans-serif;
}
/* footer */

#footer{
	clear: both;
	background: #005497;
	min-width: 960px;
	#footer-wrap{
		clear: both;
		width:960px;
		margin: 0 auto;
		.footer-link{
			overflow: hidden;
			ul{
				font-size: 12px;
				font-size: 1.2rem;
				list-style: none;
				margin: 10px;
				li{
					padding: 10px;
					float: left;
				}
			a{
				color: #fff;
				text-decoration: none;
				&:after{
			    content: " >";
					}
				&:hover{
					text-decoration: underline;
				}
			}
		}
		}
	.flink{
		width:960px;
		margin: 0 auto;
		padding: 3rem 0 1.5rem;
		p{
			color:#fff;
			font-size: 12px;
				font-size: 1.2rem;
				margin-left: 2rem;
		}
		ul{
			list-style: none;
			overflow: hidden;
				font-size: 12px;
				font-size: 1.2rem;
			margin: 1.5rem 3rem;
			li{
				float: left;
				margin: 0.5rem 0;
				a{
					display: block;
					color:#fff;
					text-decoration: none;
					padding: 0.3rem 1rem;
					border-left:1px #eee solid;
					&:hover{
						text-decoration: underline;
					}
				}
				&:last-child a{
					border-right:1px #eee solid;
				}
			}
		}
	}
		.footer-title{
			padding: 10px 0;
			display: block;
			clear: both;
			text-align:right;
		}
	}
	#copyright-wrapper{
		background: #fff;
		width: 100%;
		text-align: center;
		padding: 5px 0;
		font-size: 12px;
		font-size: 1.2rem;
	}
}

#footer #footer-wrap #sp-pc_switch {
  display: none;
}

html.mobile #footer #footer-wrap {
	position: relative;
	.footer-title{
		position: absolute;
		bottom:10px;
		right:20px;
	}
		#sp-pc_switch{
			width:49%;
			display: block;
			margin: 20px 3%;
			a{
				background: #fff;
				font-size: 1.6rem;
				padding: 10px 15px;
				display: inline-block;
				i{
					vertical-align: middle;
					padding: 0 5px;
				}
			}
		}

}







@charset "utf-8";

/************************
交通事故
Edit by sass
last up data:2016-02-16
************************/

@use 'reset' as *;
@use 'header' as *;
@use 'common' as *;
@use 'sidemenu' as *;
@use 'footer' as *;

$fontfamily:Helvetica, "ヒラギノ角ゴ Pro W3","Hiragino Kaku Gothic Pro W3",メイリオ,Meiryo,"ＭＳ Ｐゴシック",sans-serif;
*{
	font-family: $fontfamily;
}
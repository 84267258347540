/* header */

#header{
	width: 960px;
	height: 96px;
	display: block;
	margin: 0 auto;
	h1{
		font-size: 14px;
		font-size: 1.4rem;
		line-height: 22px;
		width: 470px - 17;
		padding: 0 0 0 17px;
	}
	img.sitetitle{
		float: left;
	}
	.header-contact{
		width: 480px;
		height: 70px;
		margin: -5px 0 0 0;
		float: right;
		font-size: 14px;
		font-size: 1.4rem;
		line-height: 1.2;
		p{
			span{
				font-size: 1.0rem;
			}
		}
		ul{
			margin: -10px 0 0 0;
			li{
				float: left;
				list-style: none;
			}
		}
	}
}

body#home{
	h1{
		line-height: 1;
		width: auto;
		padding: 10px 0 0;
	}
	.header-contact{
		margin: 0;
		padding: 5px 0 0;
	}
}
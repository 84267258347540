/* sidemenu */

#sidemenu{
	width: 200px;
	margin: 35px auto;
	float: right;


	ul.sidemenu-list{
		list-style: none;
		& > li{
			padding-bottom:15px;
		}
	}

.sidemenu-qa{

	border:1px #999999 solid;
	background: #f5f5f5;
	background: -webkit-gradient(linear, left top, left bottom, color-stop(0.88, #eaeaea), color-stop(0.00, #fefefe));
background: -webkit-linear-gradient(top, #fefefe 0%, #eaeaea 88%);
background: -moz-linear-gradient(top, #fefefe 0%, #eaeaea 88%);
background: -o-linear-gradient(top, #fefefe 0%, #eaeaea 88%);
background: -ms-linear-gradient(top, #fefefe 0%, #eaeaea 88%);
background: linear-gradient(to bottom, #fefefe 0%, #eaeaea 88%);
	h3{
		padding: 3px 0 0 15px;
		font-size: 18px;
		font-size: 1.8rem;
		line-height: 35px;
		border-bottom:#f76e00 4px solid;
		background: url(../images/qa-side-img01.png) no-repeat 95% bottom;
	}
	ul.sideqa-list{
		padding:5px 10px 10px;
		li{
			font-size: 12px;
			font-size: 1.2rem;
			border-bottom: 1px #999 dotted;
			background: url(../images/q-icon.png) no-repeat left 13px;
			line-height: 1.2;
			padding: 12px 0 12px 30px;
		}
	}
	.qa-pagelink{
		font-size: 12px;
		font-size: 1.2rem;
		padding:5px 10px 10px;
		text-align: right;
	}

}

	.sidenavi-title{
		background: #005497;
		color:#fff;
		font-size: 18px;
		font-size: 1.8rem;
		padding: 7px 15px;
	}


	.sidenavi-li{
		border:1px #ccc solid;

		li{
			border-top:dotted 1px #ccc;
			font-size: 16px;
			font-size: 1.6rem;
			background: url(../images/arrow-blue01.png) no-repeat 10px 50%;
			background:url(../images/arrow-blue01.png) no-repeat 10px 50%, -webkit-gradient(linear, left top, left bottom, color-stop(1.00, #f3f3f3), color-stop(0.00, #fff));
background:url(../images/arrow-blue01.png) no-repeat 10px 50%, -webkit-linear-gradient(top, #fff 0%, #f3f3f3 100%);
background:url(../images/arrow-blue01.png) no-repeat 10px 50%, -moz-linear-gradient(top, #fff 0%, #f3f3f3 100%);
background:url(../images/arrow-blue01.png) no-repeat 10px 50%, -o-linear-gradient(top, #fff 0%, #f3f3f3 100%);
background:url(../images/arrow-blue01.png) no-repeat 10px 50%, -ms-linear-gradient(top, #fff 0%, #f3f3f3 100%);
background:url(../images/arrow-blue01.png) no-repeat 10px 50%, linear-gradient(to bottom, #fff 0%, #f3f3f3 100%);
			&:first-child{
				border-top:none;
			}
			a{
				display: block;
				padding: 10px 5px 10px 35px;
				text-decoration: none;
				&:hover{
					background:url(../images/arrow-blue01.png) no-repeat 10px 50%, #EFEFEF;
					text-decoration: underline;
				}
			}
		}
	}
}

